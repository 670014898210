import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import Button from "react-bootstrap/Button";
import '../styles/SignInButton.css'; // Importar el archivo CSS

/**
 * Renders a button for logging in with a redirect
 */
export const SignInButton = () => {
    const { instance } = useMsal();

    const handleLogin = () => {
        instance.loginRedirect(loginRequest).catch((e) => {
        console.log(e);
        });
    };

    return (
        <Button
            className="custom-button ml-auto d-flex d-md-inline"
            onClick={() => handleLogin("redirect")}
        >
            Iniciar Sesión
        </Button>
    )
}
