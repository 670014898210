import React, { useEffect, useState } from 'react';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { PageLayout } from "./components/PageLayout";
import "./styles/App.css";
import UploadForm from './components/UploadForm';
import UploadFormZip from './components/UploadFormZip';

/**
 * Renders information about the signed-in user or a button to retrieve data about the user
 */
const ProfileContent = () => {
  const { accounts } = useMsal();

  return (
    <>
      <h5 className="card-title">Bienvenido {accounts[0]?.name || 'Invitado'}</h5>
    </>
  );
};

const Inicio = () => {
  const [tipoFormulario, setTipoFormulario] = useState(null);

  useEffect(() => {
    fetch(process.env.PUBLIC_URL + "/env.json")
      .then((response) => response.json())
      .then((data) => {
        setTipoFormulario(data.tipoFormulario);
      })
      .catch((error) => console.error("Error fetching tipoFormulario:", error));
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        {tipoFormulario === "UploadForm" && <UploadForm />}
        {tipoFormulario === "UploadFormZip" && <UploadFormZip />}
      </header>
    </div>
  );
};

/**
 * If a user is authenticated the ProfileContent component above is rendered. Otherwise a message indicating a user is not authenticated is rendered.
 */
const MainContent = () => {
  const [ambienteActual, setAmbienteActual] = useState(null);

  useEffect(() => {
    fetch(process.env.PUBLIC_URL + "/env.json")
      .then((response) => response.json())
      .then((data) => {
        setAmbienteActual(data.ambienteActual);
      })
      .catch((error) => console.error("Error fetching ambienteActual:", error));
  }, []);

  return (
    <div className="App">
      {ambienteActual === "ambientePruebas" && (
        <Inicio />
      )}
      {ambienteActual === "ambienteProduccion" && (
        <>
          <AuthenticatedTemplate>
            <ProfileContent />
            <br />
            <br />
            <Inicio />
          </AuthenticatedTemplate>

          <UnauthenticatedTemplate>
            <h5 className="card-title">Por favor inicia sesión para usar el sistema.</h5>
          </UnauthenticatedTemplate>
        </>
      )}
    </div>
  );
};

export default function App() {
  return (
    <PageLayout>
      <MainContent />
    </PageLayout>
  );
}
