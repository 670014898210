import React, { useEffect, useState } from "react";

function UploadFormZip() {
  const [ambienteActual, setAmbienteActual] = useState(null);
  const [sslEnabled, setSslEnabled] = useState(null);
  const [files, setFiles] = useState([]);
  const [error, setError] = useState(null);
  const [isProcess, setIsProcess] = useState(false);
  const [processCompleted, setProcessCompleted] = useState(false);
  const [fileDownloaded, setFileDownloaded] = useState(false);
  const [resetKey, setResetKey] = useState(Date.now()); // nueva key para reiniciar el input
  const [uploadButtonDisabled, setUploadButtonDisabled] = useState(false); // Nuevo estado para controlar el botón de Cargar Archivos
  const [buttonDisabled, setButtonDisabled] = useState(false); // Nuevo estado para controlar el botón de Terminar proceso

  useEffect(() => {
    fetch(process.env.PUBLIC_URL + "/env.json")
      .then((response) => response.json())
      .then((data) => {
        setAmbienteActual(data[data.ambienteActual]);
        setSslEnabled(data.SSL); // Establecer el valor de SSL
      })
      .catch((error) => {
        console.error("Error fetching ambiente:", error);
        setError("Error al obtener la información del ambiente.");
      });
  }, []);

  const uploadFile = async (file) => {
    setUploadButtonDisabled(true); // Desactivar el botón de Cargar Archivos
    setResetKey(null); // Desactivar el botón de Elegir Archivos
    const formData = new FormData();
    formData.append("zipfile", file);

    const protocol = sslEnabled ? "https" : "http";
    const port = sslEnabled ? "" : ":2900";
    const uploadURL = `${protocol}://${ambienteActual.ip}${port}/uploads/zip`;

    try {
      setIsProcess(true); // Comenzar a procesar
      const response = await fetch(uploadURL, {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "Error al cargar el archivo.");
      }

      alert(`Archivo ${file.name} procesado con éxito.`);
      setProcessCompleted(true);
    } catch (error) {
      console.error("Error:", error);
      setError(`Error al cargar el archivo ${file.name}.`);
      throw error;
    } finally {
      setIsProcess(false); // Dejar de procesar
    }
  };

  const downloadFile = async () => {
    setButtonDisabled(true); // Desactivar el botón cuando se inicie la descarga

    const protocol = sslEnabled ? "https" : "http";
    const port = sslEnabled ? "" : ":2900";
    const downloadURL = `${protocol}://${ambienteActual.ip}${port}/download`;

    try {
      const response = await fetch(downloadURL, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ message: "AutoMaticGenFront" }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "Error al descargar el archivo.");
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'deducciones_nomina.zip';
      a.click();

      setFileDownloaded(true);
    } catch (error) {
      console.error("Error:", error);
      setError(`Error al descargar el archivo.`);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!files.length) {
      setError("Por favor selecciona al menos un archivo.");
      return;
    }

    setError(null);

    for (const file of files) {
      try {
        await uploadFile(file);
      } catch (error) {
        break;
      }
    }

    setFiles([]);
  };

  const handleFileChange = (event) => {
    setFiles(Array.from(event.target.files));
    setError(null);
    setUploadButtonDisabled(false); // Activar el botón de Cargar Archivos cuando se selecciona un archivo
  };

  const resetForm = () => {
    setProcessCompleted(false);
    setFileDownloaded(false);
    setFiles([]);
    setResetKey(Date.now()); // Reiniciar el input de archivos
    setUploadButtonDisabled(false); // Reactivar el botón de Cargar Archivos
  }

  return (
    <div style={{ textAlign: "center", backgroundColor: 'white', color: '#789447' }}>
      <h2>Carga del archivo .zip del proceso de deducciones de nómina</h2>
      {error && <div style={{ color: "red" }}>{error}</div>}
      {isProcess && <div style={{ fontSize: "2em", color: "magenta" }}>Procesando...</div>}
      {!isProcess && processCompleted && !fileDownloaded &&
      <button
        style={{
          backgroundColor: buttonDisabled ? 'grey' : '#789447',
          borderColor: buttonDisabled ? 'grey' : '#789447',
          color: 'white',
          borderRadius: '8px'
        }}
        onClick={downloadFile}
        disabled={buttonDisabled}
      >
        Terminar Proceso
      </button>}
      {fileDownloaded && <button style={{
         backgroundColor: '#789447',
         borderColor: '#789447',
         color: 'white',
         borderRadius: '8px'
      }} onClick={resetForm}>Iniciar Nuevo Proceso</button>}
      <form onSubmit={handleSubmit}>
        { !processCompleted && ( // Ocultar el botón de "Elegir Archivos" cuando se muestra "Terminar Proceso"
          <label htmlFor="fileInput" style={{
            backgroundColor: isProcess || processCompleted ? 'grey' : '#789447',
            borderColor: isProcess || processCompleted ? 'grey' : '#789447',
            color: 'white',
            borderRadius: '8px',
            padding: '10px'
          }}>
            Elegir Archivos
            <input
              id="fileInput"
              key={resetKey}
              type="file"
              accept=".zip"
              onChange={handleFileChange}
              multiple
              disabled={isProcess || processCompleted}
              style={{ display: 'none' }}
            />
          </label>
        )}
        {files.length > 0 && !error && (
          <div>
            <p>Archivos seleccionados:</p>
            <ul style={{ fontSize: "1em" }}>
              {files.map((file) => (
                <li key={file.name}>{file.name}</li>
              ))}
            </ul>
          </div>
        )}
        {files.length > 0 && (
          <button
          type="submit"
          disabled={isProcess || processCompleted || uploadButtonDisabled}
          style={{
            backgroundColor: uploadButtonDisabled ? 'grey' : '#789447',
            borderColor: uploadButtonDisabled ? 'grey' : '#789447',
            color: 'white',
            borderRadius: '8px'
          }}
        >
          Cargar Archivos
        </button>
        )}
      </form>
    </div>
  );
}

export default UploadFormZip;
