import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";

import { useIsAuthenticated } from "@azure/msal-react";
import { SignInButton } from "./SignInButton";
import { SignOutButton } from "./SignOutButton";

/**
 * Renders the navbar component with a sign-in or sign-out button depending on whether or not a user is authenticated
 * @param props
 */
export const PageLayout = (props) => {
    const isAuthenticated = useIsAuthenticated();

    return (
        <>
            <div style={{ backgroundColor: '#789447', height: '5px' }}></div>
            <Navbar expand="lg" style={{ backgroundColor: '#ffffff' }}>
                <Navbar.Brand href="/" className="d-lg-inline-block d-flex mx-lg-auto mx-0 justify-content-center" style={{ marginLeft: '20px', color: 'black', fontWeight: 'bold' }}>
                    <img
                        src={`${process.env.PUBLIC_URL}/logo_autogen.png`}
                        className="d-inline-block align-top"
                        alt="Logo"
                        style={{ width: '100%', height: 'auto', marginLeft: '20px' }}
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mx-auto d-lg-inline-block d-flex justify-content-center">
                        <a className="navbar-brand" href="/" style={{ color: 'black', fontWeight: 'bold', marginLeft: '20px'}}>SISTEMA DE DEDUCCIONES DE NÓMINA</a>
                    </Nav>
                    <Nav className="d-lg-inline-block d-flex justify-content-center" style={{marginLeft: '20px', marginRight: '20px' }}>
                        { isAuthenticated ? <SignOutButton /> : <SignInButton /> }
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
            <div style={{ backgroundColor: '#789447', height: '5px' }}></div>
            <br />
            {props.children}
        </>
    );
};
