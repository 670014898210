import React, { useEffect, useState } from "react";

function UploadForm() {
  const [ambienteActual, setAmbienteActual] = useState(null);
  const [files, setFiles] = useState([]);
  const [error, setError] = useState(null);
  const [uploadStep, setUploadStep] = useState(0);
  const [isProcessFinished, setIsProcessFinished] = useState(false);
  const [isUploadComplete, setIsUploadComplete] = useState(false);

  const uploadURLs = [
    "/uploads/load",
    "/uploads/transfer",
    "/uploads/social",
    "/uploads/helper",
    "/uploads/inventory",
  ];

  useEffect(() => {
    fetch(process.env.PUBLIC_URL + "/env.json")
      .then((response) => response.json())
      .then((data) => setAmbienteActual(data[data.ambienteActual]))
      .catch((error) => {
        console.error("Error fetching ambiente:", error);
        setError("Error al obtener la información del ambiente.");
      });
  }, []);

  const removeAccents = (str) => {
    const map = {
      á: "a", é: "e", í: "i", ó: "o", ú: "u",
      Á: "A", É: "E", Í: "I", Ó: "O", Ú: "U",
      Ã: "Ñ", ã: "ñ", '': ""
    };

    return str.replace(/[áéíóúÁÉÍÓÚÃã]/g, (match) => map[match]);
  };

  const uploadFile = async (file, uploadURL) => {
    const formData = new FormData();
    const fileField = uploadStep === 1 ? "files" : "file";
    formData.append(fileField, new File([file], removeAccents(file.name)));

    try {
      const response = await fetch(uploadURL, {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "Error Uno, al cargar el archivo.");
      }
    } catch (error) {
      console.error("Error:", error);
      setError(`Error al cargar el archivo ${file.name}.`);
      throw error;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!files.length) {
      setError("Por favor selecciona al menos un archivo.");
      return;
    }

    setError(null);
    setUploadStep(uploadStep + 1);

    const uploadURL = `http://${ambienteActual.ip}:2900${uploadURLs[uploadStep]}`;

    for (const file of files) {
      try {
        await uploadFile(file, uploadURL);
        alert(`Archivo ${file.name} cargado con éxito.`);
        if (uploadStep === 2) {
          setIsUploadComplete(true);
        }
      } catch (error) {
        break;
      }
    }

    setFiles([]);
  };

  const handleFileChange = (event) => {
    setFiles(Array.from(event.target.files));
    setError(null);
  };

  const resetUploadProcess = () => {
    setFiles([]);
    setError(null);
    setUploadStep(0);
    setIsProcessFinished(false);
    setIsUploadComplete(false);
  };

  const stepColors = ["cyan", "green", "magenta", "yellow", "blue", "white"];
  const stepMessages = [
    "Por favor, cargue el archivo de facturación.",
    "Por favor, cargue el archivo de nómina.",
    "Por favor, cargue el archivo de seguridad social.",
    "Por favor, cargue el archivo de deducción de ayudantes.",
    "Por favor, cargue el archivo de Inventario RST.",
    "Todos los archivos han sido cargados.",
  ];

  const handleFinished = async () => {
    try {
      const response = await fetch(
        `http://${ambienteActual.ip}:2900/uploads/finished`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ message: "Terminado" }),
        }
      );

      if (response.ok) {
        alert("Proceso CIERRE completado con éxito");
        const downloadResponse = await fetch(
          `http://${ambienteActual.ip}:2900/download`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ message: "AutoMaticGenFront" }),
          }
        );
        if (downloadResponse.ok) {
          const blob = await downloadResponse.blob();
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "deducciones_nomina.zip";
          document.body.appendChild(a);
          a.click();
          a.remove();
          setIsProcessFinished(true);
        } else {
          const downloadErrorData = await downloadResponse.json();
          setError(downloadErrorData.error || "Error al descargar el archivo.");
        }
      } else {
        const errorData = await response.json();
        setError(errorData.error || "Error Uno, en el cierre del proceso.");
      }
    } catch (error) {
      console.error("Error:", error);
      setError(`Error en el cierre del proceso.`);
    }
  };

  return (
    <div style={{ textAlign: "center" }}>
      <img src="logo_autogen.png" alt="Logo AutoGen" />
      <h2>Carga de archivos del proceso de deducciones de nómina</h2>
      <h3>
        <span style={{ color: stepColors[uploadStep] }}>Paso {uploadStep + 1}:</span>{" "}
        {stepMessages[uploadStep]}
      </h3>
      {error && <div style={{ color: "red" }}>{error}</div>}
      {uploadStep < 5 ? (
        <form onSubmit={handleSubmit}>
          <input
            type="file"
            accept=".xlsx"
            onChange={handleFileChange}
            multiple
          />
          {files.length > 0 && !error && (
            <div>
              <p>Archivos seleccionados:</p>
              <ul style={{ fontSize: "1em" }}>
                {files.map((file) => (
                  <li key={file.name}>{file.name}</li>
                ))}
              </ul>
            </div>
          )}
          <button type="submit">Cargar archivos</button>
        </form>
      ) : (
        <div>
          {!isProcessFinished ? (
            <button
              onClick={handleFinished}
              style={{ backgroundColor: "orange" }}
            >
              Terminar proceso
            </button>
          ) : (
            <button
              onClick={resetUploadProcess}
              style={{ backgroundColor: "green" }}
            >
              Iniciar nuevo proceso
            </button>
          )}
        </div>
      )}
    </div>
  );
}

export default UploadForm;
